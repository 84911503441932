export const homePageContent = {
  heroText: "Hola, me llamo Sebastià y trabajo como terapeuta gestalt en  ",
  heroTextMobile: "Hola, me llamo Sebastià y trabajo como terapeuta gestalt en Palma de Mallorca ",
  paragraph01: "Entiendo la terapia como un encuentro entre dos personas donde la presencia, la transparencia y responsabilidad son los pilares que sostienen lo que va sucediendo.",
  paragraph1: "{BoxedWordClockWise|Martin Buber} explica magníficamente esta relación yo-tú como un diálogo: “Sin finalidad, sin codicia, ni preconcepto. Donde cada uno tiene la posibilidad de descubrirse a sí mismo sin ser manipulado por el otro”.",
  paragraph2: "Este diálogo se enmarca dentro del contexto de la Gestalt que actúa como marco de trabajo, como encuadre. Según {BoxedWordClockWise| Fritz Perls} todo lo que buscamos ya existe en el momento presente. Hay por tanto un compromiso profundo con el estar presentes en el aquí y ahora, considerándolo como el ámbito principal para nuestra labor.",
  paragraph3: "Seguimos con el {BoxedWordClockWise| darse cuenta}, el percatarse. Darse cuenta de todo lo que nos ocurre, pero sobre todo darse cuenta de que vivimos desconectados de nosotros mismos. Es este deterioro del darse cuenta, lo que hay que mirar. Esta dificultad para percibir la realidad, poniendo atención de manera compulsiva en algunos procesos y dejando fuera otros tantos.",
  paragraph4: "Continuamos con la {BoxedWordClockWise2| responsabilidad}, no como un deber sino un hecho inevitable. Somos los actores responsables de cualquier cosa que hagamos. Nuestra única alternativa es reconocer tal responsabilidad o negarla. Y percatarse de esta verdad, nos cura de nuestras mentiras. Así decía {BoxedWordClockWise2| Claudio Naranjo}.",
  paragraph5: "Por tanto, tenemos la presencia, la conciencia y la responsabilidad como guías en este camino. ",
  paragraph6: "Por último pero no menos importante, en gestalt hablamos de autorregulación organísmica y es un nombre extravagante para un concepto simple: {BoxedWordClockWise| Fe}. Fe en que existen soluciones y que somos capaces de encontrarlas por nosotros mismos. Fe en que hay una pulsión de vida en nuestro interior que nos impulsa a desarrollar nuestro potencial, crecer y alejarnos del sufrimiento. Aunque el camino hacia la autorregulación pueda ser difícil o tortuoso, debemos confiar en que tenemos la capacidad de acceder a ella y encontrar la respuesta que buscamos.",
  paragraph7: "Y así, es como yo entiendo este camino y la manera en que acompaño.",
};