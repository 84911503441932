export const grey = '#6D6E6F';
export const lightGrey = '#C0C0C0';
export const superLightGrey = '#fafafa';
export const mainColor = '#FF6F50';
export const darkRed = '#8c0d0d';
export const substackYellowBackground= '#FAF8F0';
export const substackdarkerYellowBackground= '#F5EDDD';
export const Sizes = {
  S: 'small',
}
export const HEADER_HEIGHT = '5.5em';
export const FOOTER_HEIGHT = '3em';
