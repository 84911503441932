import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`

`;

export const CookiesPolicy = () => {
  return(
    <Wrapper>
        Hola, m'ha fet peresa fer-ho... Però ho faré! 
    </Wrapper>
  )
}